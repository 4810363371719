// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import ActivityTypes from '../views/ActivityTypes.vue';
import ActivityLogs from '../views/ActivityLogs.vue';
import Operators from '../views/Operators.vue';
import Logger from '../views/Logger.vue';
import Roles from '../views/Roles.vue';
import Reports from '../views/Reports.vue';
// import { authGuard } from "@auth0/auth0-vue";
import { authGuard } from '../auth0';
// import App from '../App.vue';


// Define routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: '/activity-types',
    name: 'ActivityTypes',
    component: ActivityTypes,
    beforeEnter: authGuard,
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    beforeEnter: authGuard,
  },
  {
    path: '/activity-logs',
    name: 'ActivityLogs',
    component: ActivityLogs,
    beforeEnter: authGuard,
  },
  {
    path: '/operators',
    name: 'Operators',
    component: Operators,
    beforeEnter: authGuard,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    beforeEnter: authGuard,
  },
  {
    path: '/logger',
    name: 'Logger',
    component: Logger,
    beforeEnter: authGuard,
  },
];


// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
