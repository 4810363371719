<template>
  <div>
    <v-app-bar dense elevation="0" outlined color="primary" prominent>
      <v-app-bar-nav-icon
        v-if="$vuetify.display.mobile"
        variant="text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>3BOPS</v-toolbar-title>
      <!-- Links -->
      <div class="d-none d-md-flex">
        <router-link
          v-for="route of routes"
          :key="route.path"
          :to="route.path"
          v-slot="{ navigate, href, isActive }"
        >
          <v-btn
            :to="href"
            :class="{ 'v-btn--active': isActive }"
            color="secondary"
            @click="navigate"
          >
            {{ route.name }}
          </v-btn>
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <!-- Auth buttons -->
      <p class="pa-2 d-none d-md-flex" v-if="isAuthenticated">
        Logged in as {{ user.nickname }}
      </p>
      <AuthButtons class="d-none d-md-flex" />

      <v-btn
        :prepend-icon="
          theme === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night'
        "
        @click="toggleTheme"
        outlined
      ></v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.display.mobile"
      v-model="drawer"
      temporary
    >
      <v-list density="compact" nav>
        <router-link
          custom
          v-for="route in routes"
          :key="route.path"
          :to="route.path"
          v-slot="{ navigate, href, isActive }"
        >
          <v-btn
            rounded="0"
            block
            :to="href"
            :class="{ 'v-btn--active': isActive }"
            class="my-1"
            color="secondary"
            @click="navigate"
          >
            {{ route.name }}
          </v-btn>
        </router-link>
        <!-- Mobile Auth Info in Drawer -->
        <v-list-item v-if="isAuthenticated" class="pa-2">
          <v-divider class="my-2 mx-0 px-0"/>
          Logged in as {{ user.nickname }}
          <v-divider class="my-2 mx-0 px-0"/>
          <AuthButtons />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AuthButtons from "../components/AuthButtons";
import { inject } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { authStore } from "../store/authStore";

export default {
  name: "NavBar",
  components: { AuthButtons },
  data() {
    return {
      drawer: null,
    };
  },
  setup() {
    const theme = inject("theme");
    const { user, isAuthenticated } = useAuth0();
    const routesList = [
      {
        path: "/operators",
        name: "Operators",
        authRequired: true,
        adminRequired: true,
      },
      {
        path: "/roles",
        name: "Roles",
        authRequired: true,
        adminRequired: true,
      },
      {
        path: "/activity-types",
        name: "Activity Types",
        authRequired: true,
        adminRequired: true,
      },
      {
        path: "/activity-logs",
        name: "Activity Logs",
        authRequired: true,
        adminRequired: true,
      },
      {
        path: "/reports",
        name: "Reports",
        authRequired: true,
        adminRequired: true,
      },
      {
        path: "/logger",
        name: "Logger",
        authRequired: true,
        adminRequired: false,
      },
    ];

    return { theme, user, isAuthenticated, routesList };
  },
  computed: {
    isAdmin() {
      if (!authStore.auth0Operator) return false;
      return authStore.auth0Operator.operator_setting.admin;
    },
    routes() {
      let routes = this.isAuthenticated
        ? this.routesList
        : this.routesList.filter((route) => !route.authRequired);
      routes = this.isAdmin
        ? routes
        : routes.filter((route) => !route.adminRequired);
      return routes;
    },
  },
  methods: {
    toggleTheme() {
      this.$emit("toggleTheme");
    },
  },
};
</script>
