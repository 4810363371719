<template>
  <v-container max-width="70vw">
    <!-- Data Table for Open Activities By User -->
    <v-card class="mb-5">
      <div class="d-flex">
        <v-card-title>Open Activities By User </v-card-title> <v-spacer />
        <v-btn
          icon="mdi-refresh"
          variant="text"
          @click="getOpenActivitiesByUser"
          class="pa-2"
        />
      </div>
      <v-card-text>
        <v-data-table
          :headers="openActivitiesHeaders"
          :items="openActivities"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Snackbar for Notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      :color="snackbar.color"
      top
      >{{ snackbar.message }}</v-snackbar
    >
  </v-container>
</template>
  
  <script>
import internalApi from "../apis/internal";
import { useLoadingState } from "../store/loadingState";
import { getHeaders } from "../helpers/commonFunctions";

export default {
  name: "ReportsPage",
  data() {
    return {
      search: "",
      openActivities: [],
      openActivitiesHeaders: [],
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      loadingState: useLoadingState(),
    };
  },

  created() {
    this.getOpenActivitiesByUser();
  },
  methods: {
    // Fetch all roles
    async getOpenActivitiesByUser() {
      try {
        const response = await internalApi.genericGet(
          "activities/open-activities-by-user"
        );
        this.openActivities = response;
        this.openActivitiesHeaders = getHeaders(response, [], false); // Adjust headers as necessary
      } catch (error) {
        this.showSnackbar("Error fetching roles", "error");
      }
    },

    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    // Reset the form to its default state
  },
};
</script>
  
  <style scoped>
</style>
  