import { createAuth0 } from '@auth0/auth0-vue';
import { watch } from 'vue';
import { authStore } from './store/authStore';  // Import the global store
import internalApi from './apis/internal';



const auth0 = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  },
});

// creating my own authGuard instead of using  import { createAuth0, authGuard } from '@auth0/auth0-vue';
// this way i can handle roles if needed
const authGuard = (to, from, next) => {
  const handleAuthCheck = () => {
    // If the user is authenticated, continue with the route
    if (auth0.isAuthenticated.value) {
      // const user = auth0.user.value;
      // const userRoles = user['additional-info/roles'] || [];
      // const genericUserOnlyPages = ['/activity-log']
      // if (!userRoles.includes('3BOPS-generic-user') && genericUserOnlyPages.includes(to.path)) {
      //   return next('/');
      // }
      if(authStore.auth0Operator) return next();
      return internalApi.genericGet('activities/current-operator').then( user =>{
        authStore.auth0Operator = user
        if(!user.operator_setting) return next('/logger')
        if(!user.operator_setting.admin) return next('/logger')
        return next(); // Allow the navigation
      })

     
    }

    // Otherwise, redirect to login
    auth0.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If Auth0 is already done loading, proceed with the auth check
  if (!auth0.isLoading.value) {
    return handleAuthCheck();
  }

  // If still loading, use Vue's `watch` to wait for loading to complete
  watch(
    () => auth0.isLoading.value,
    (loading) => {
      if (!loading) {
        handleAuthCheck();
      }
    }
  );
};





export {auth0, authGuard}