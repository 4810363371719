<template>
  <v-container>
    <v-card class="mb-5" v-if="!site">
      <v-alert v-if="!site" type="info"> Please Select a Site </v-alert>
      <SiteSelect v-model="site" />
    </v-card>

    <div v-if="site">
      <v-card v-if="!auth0Operator" class="mb-5">
        <v-card-title>Loading User</v-card-title>
      </v-card>

      <v-card v-if="isGenericUser && !loadedOperator" class="mb-5">
        <v-card-title>Scan Alias</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="onAliasSubmit">
            <v-text-field v-model="alias" label="Alias" required  ref="aliasField"></v-text-field>
            <v-btn type="submit" color="primary">Submit</v-btn>
          </v-form>
          <v-alert v-if="aliasError" type="error">{{ aliasError }}</v-alert>
        </v-card-text>
      </v-card>

      <v-card v-else class="mb-5" color="secondary">
        <!-- either user info or generic user alias here -->
        <v-row v-if="isGenericUser">
          <v-col cols-="12" class="justify-center" md="6">
            <v-card-title>Logged in as {{ alias }}</v-card-title>
          </v-col>
          <v-col cols="12" class="d-flex justify-end" md="6">
            <v-btn class="ma-4" variant="outlined" @click="changeAlias">Change user</v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- Activity Log Section -->
      <v-card v-if="loadedOperator" class="mb-5 pb-5">
        <v-alert v-if="openActivity" type="info">
          <v-row>
            <v-col cols="12"  md="6" lg="9" >
              <span>You're currently
                {{ getActivityTypeName(openActivity.activity_type_id) }} 
                {{ openActivity.reference_one ? ` - ${openActivity.reference_one}` : ''}}
                {{ openActivity.location ? ` - ${openActivity.location}` : ''}}
                {{ openActivity.customer ? ` - ${openActivity.customer}` : ''}} |
                started at: {{ formatDate(openActivity.activity_start, true, false) }}</span>
            </v-col>
            <v-col cols="12" class="d-flex justify-end" md="6" lg="3"  >
              <v-btn variant="outlined" @click="closeActivity()" :loading="loadingState.isLoading">Close Open
                Activity</v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert v-else>No currently open activity</v-alert>
        <v-card-text>
          <!-- Display current activity if exists -->

          <v-card-title class="mb-2">Start {{ openActivity ? 'another' : 'an' }} activity at
            {{ site.reference_one }}
            {{
              openActivity ? '(this will close your previous one)' : ''
            }}</v-card-title>


          <!-- Activity Type Buttons -->
           <v-row class="px-2">
            <v-col cols="3" v-for="type in visibleActivityTypes" :key="type.id" class="pa-1">
              <v-btn  @click="activitySelected(type)" class="text-h7 font-weight-bold wrapped-btn" block height="15vh"
            color="primary" :loading="loadingState.isLoading">{{ type.name }}</v-btn>
            </v-col>
            <p v-if="visibleActivityTypes.length < 1">No activities available, please assign a role to this user first</p>
           </v-row>
          
          <!-- customer dropdown -->
          <v-dialog v-model="showAdditional" max-width="600px">
            <v-card v-if="activityForm.activityType">
              <v-card-title>Please provide additional details</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="additionalSelected">
                  <CustomerSelect v-model="activityForm.customer" :returnCodeOnly="true"
                    v-if="activityForm.activityType.customer_required" />
                  <v-text-field ref="locationField" v-model="activityForm.location" v-if="activityForm.activityType.location_required"
                    label="location" />
                  <v-text-field ref="referenceField" v-model="activityForm.reference" v-if="activityForm.activityType.reference_one_required"
                    label="reference" />
                  <v-btn :disabled="needsAdditional" type="submit" color="primary"
                    :loading="loadingState.isLoading">Start Activity</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </div>

    <!-- add alias menu -->
    <v-dialog persistent v-model="needsSnapAlias" max-width="600px">
      <v-card>
        <v-card-title>Please provide a SNAP alias</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="submitSnapAlias">
            <v-text-field v-model="submitAlias" label="alias"></v-text-field>
            <v-btn type="submit" color="primary" :loading="loadingState.isLoading">Add alias</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Snackbar for Notifications -->
    <v-snackbar v-model="snackbar.show" :timeout="5000" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import internalApi from '../apis/internal'; // Adjust path based on your project
import CustomerSelect from '../components/CustomerSelect.vue';
import SiteSelect from '../components/SiteSelect.vue';
import { useLoadingState } from '../store/loadingState';
import { authStore } from '../store/authStore';
import { formatDate } from '../helpers/commonFunctions';

export default {
  name: 'LoggerPage',
  components: { SiteSelect, CustomerSelect },
  data() {
    return {
      alias: '',
      aliasFound: false,
      aliasError: '',


      loadedOperator: false,
      openActivity: null,
      activityTypes: [],
      site: null,
      snackbar: {
        show: false,
        message: '',
        color: '',
      },
      snapDataSourceId: null,
      submitAlias: null,
      needsSnapAlias: false,
      loadingState: useLoadingState(),
      activityForm: {},
      showAdditional: false,

    };
  },
  created() {
    this.resetActivityForm()
    this.getOperatorData();
    this.getActivityTypes();
  },
  watch:{
    site(newVal){
      if(newVal && !this.loadedOperator && this.isGenericUser){
        this.focusField('aliasField')
      }
    },
    loadedOperator(newVal){
      if(!newVal && this.isGenericUser){
        this.focusField('aliasField')
      }
    },
    showAdditional(newVal){
      if(newVal){
        if(this.activityForm.activityType.location_required){
          this.focusField('locationField')
        }else if(this.activityForm.activityType.reference_one_required){
          this.focusField('referenceField')
        }
        
      }
    }
  },
  computed: {
    needsAdditional() {
      if (!this.activityForm.activityType) return false
      if (this.activityForm.activityType.location_required && !this.activityForm.location) return true
      if (this.activityForm.activityType.reference_one_required && !this.activityForm.reference) return true
      return false
    },
    auth0Operator() {
      return authStore.auth0Operator
    },
    isGenericUser() {
      if (!this.auth0Operator) return false;
      if (!this.auth0Operator.operator_setting) return false;
      return this.auth0Operator.operator_setting.generic_user;
    },
    visibleActivityTypes() {
      if (!this.loadedOperator) return [];
      if (!this.loadedOperator.operator_setting) return [];
      if (!this.loadedOperator.operator_setting.role) return [];
      const operatorAT = this.loadedOperator.operator_setting.role.role_activity_types
      if (operatorAT < 1) return []
      let filteredActivityTypes = []
      // let filteredActivityTypes = this.activityTypes.filter((type) =>
      // operatorAT.some(
      //     (opTypes) => type.id === opTypes.activity_type_id
      //   )
      // );
      
      for(const activity of operatorAT){
        let foundActivity = this.activityTypes.find(el => el.id === activity.activity_type_id)
        if(foundActivity){
          let newObj = {...foundActivity }
          // use role_activity_types sort rank to sort the activity types
          newObj.sort_rank = activity.sort_rank
          filteredActivityTypes.push(newObj)
        }
      }
      filteredActivityTypes = filteredActivityTypes.sort( (a, b) => b.sort_rank - a.sort_rank)
      
      return filteredActivityTypes;
    },
  },
  methods: {
    formatDate,
    focusField(ref) {
      this.$nextTick(() => {
        this.$refs[ref].focus();
      });
    },
  
    activitySelected(activity) {
      this.activityForm.activityType = activity
      if (activity.customer_required || activity.location_required || activity.reference_one_required) {
        this.showAdditional = true
        return
      } else {
        this.startActivity()
      }
    },
    additionalSelected() {
      if (this.needsAdditional) return
      this.showAdditional = false
      this.startActivity()
    },
    async getSnapDataSourceId() {
      try {
        const response = await internalApi.genericGet('DataSource');
        const snapDataSourceId = response.find(source => source.source_name === 'SNAP')

        this.snapDataSourceId = snapDataSourceId.id;
        return snapDataSourceId.id
      } catch {
        this.showSnackbar('Error loading snap source id', 'error');
        return null
      }
    },
    getActivityTypeName(activityTypeId) {
      let found = this.activityTypes.find((type) => type.id === activityTypeId);
      return found ? found.name : activityTypeId;
    },
    closeLoadedOperator() {
      this.loadedOperator = null;
      this.alias = '';
      this.openActivity = null;
    },
    async getOperatorData() {
      try {
        if (!this.isGenericUser) {
          this.loadedOperator = this.auth0Operator;
          this.loadOpenActivity();
          this.checkOperatorNeedsSnapAlias(this.loadedOperator)
        }
      } catch {
        this.showSnackbar('error loading operator data', 'error');
      }
    },
    async submitSnapAlias() {
      const form = {
        alias: this.submitAlias,
        data_source_id: this.snapDataSourceId
      }
      try {

        await internalApi.genericPost(
          `/activities/add-alias-to-user`,
          form
        );
        this.needsSnapAlias = false

      } catch (error) {
        const errorMsg = error.response ? error.response.data.error : 'Error submitting snap alias'
        this.showSnackbar(errorMsg, 'error');
      }

    },
    async checkOperatorNeedsSnapAlias(operator) {
      // if user is not generic user or admin, check if this user has a snap alias, 
      // if it doesn't then one needs to be created
      const snapDataSourceId = await this.getSnapDataSourceId()
      if (!snapDataSourceId) return false
      this.snapDataSourceId = snapDataSourceId
      const snapAliasFound = operator.operator_aliases.find(alias => alias.data_source_id === snapDataSourceId)
      if (!snapAliasFound) {
        this.needsSnapAlias = true
      }
    },
    async loadOpenActivity() {
      try {
        const operatorId = this.loadedOperator.id;
        const openActivity = await internalApi.genericGet(
          'activities/open-activity',
          { operator_id: operatorId }
        );
        this.openActivity = openActivity;
      } catch {
        this.showSnackbar('error loading open activity', 'error');
      }
    },
    async getActivityTypes() {
      try {
        const types = await internalApi.genericGet('ActivityTypes');
        this.activityTypes = types.filter((type) => type.visible);
      } catch (error) {
        this.showSnackbar('error loading activity types', 'error');
      }
    },

    async onAliasSubmit() {
      try {
        if (!this.alias) return
        this.openActivity = null;
        const operator = await internalApi.genericGet(
          `/activities/operator-by-alias`,
          { alias: this.alias, data_source_id: this.snapDataSourceId }
        );
        if (operator) {
          this.aliasFound = true;
          this.aliasError = '';
          this.loadedOperator = operator;
          this.loadOpenActivity();
        }
      } catch {
        this.alias = '';
        this.aliasError = 'Alias not found. Please try again.';
      }
    },

    async closeActivity() {
      try {
        const response = await internalApi.genericPost('/activities/log', {
          alias: this.auth0Operator.operator_setting.generic_user
            ? this.alias
            : null,
          close_current_activity: true,
        });

        if (response) {
          this.showSnackbar('activity closed', 'success');
          if (this.isGenericUser) {
            this.closeLoadedOperator();
          } else {
            this.loadOpenActivity();
          }
        }
      } catch {
        this.showSnackbar('failed to close activity', 'error');
      }
    },
    changeAlias() {
      this.alias = null;
      this.loadedOperator = null;
    },
    resetActivityForm() {
      this.activityForm = {
        activityType: null,
        customer: null,
        location: null,
        reference: null
      }
    },
    async startActivity() {
      try {
        let reqBody = {
          alias: this.auth0Operator.operator_setting.generic_user
            ? this.alias
            : null,
          activity_type_id: this.activityForm.activityType.id,
          site_id: this.site.id,
          location: this.activityForm.location ?? null,
          reference_one: this.activityForm.reference ?? null,
          customer: this.activityForm.customer ?? null
        }

        const response = await internalApi.genericPost('/activities/log', reqBody);

        if (response) {
          this.resetActivityForm()
          this.showSnackbar('activity started', 'success');
          if (this.isGenericUser) {
            this.closeLoadedOperator();
          } else {
            this.loadOpenActivity();
          }
        }
      } catch {
        this.showSnackbar('failed to start activity', 'error');
      }
    },
    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>

<style scoped>
.wrapped-btn {
  white-space: normal;  /* Allow text to wrap */
  text-align: center;   /* Center-align the text */
  overflow-wrap: break-word !important; /* Break long words if necessary */
  word-wrap: break-word;     /* Ensure word breaking on older browsers */
}
</style>
