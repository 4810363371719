<template>
  <v-container max-width="70vw">
    <!-- Form to Create Operator and Alias -->
    <v-card class="mb-5">
      <v-card-title>Create Operator</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createOperatorWithAlias">
          <!-- Operator Form Fields -->
          <v-text-field
            v-model="form.operator_name"
            label="Operator Name"
            required
          ></v-text-field>

          <v-switch
            v-model="form.admin"
            label="Admin"
            color="secondary"
          ></v-switch>

          <v-switch
            v-model="form.generic_user"
            label="Generic User"
            color="secondary"
          ></v-switch>

          <!-- Role Selector -->
          <v-select
            v-model="form.role_id"
            :items="roles"
            item-title="name"
            item-value="id"
            label="Role"
            required
          ></v-select>

          <!-- Alias Section-->
          <v-card-subtitle>Alias</v-card-subtitle>
          <v-text-field v-model="form.alias" label="Alias"></v-text-field>

          <!-- Data Source Selector (v-select loaded from API) -->
          <v-select
            v-model="form.data_source_id"
            :items="dataSources"
            item-title="source_name"
            item-value="id"
            label="Data Source"
          ></v-select>

          <v-btn
            type="submit"
            color="primary"
            :loading="loadingState.isLoading"
            :disabled="!canCreateUser"
            >Create</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Data Table for Operators and Aliases -->
    <v-card class="mb-5">
      <v-card-title>Operators</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="operatorsHeaders"
          :items="operators"
          :search="search"
          items-per-page="5"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-btn icon @click="deleteOperator(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Select Existing Operator -->
    <v-card class="mb-5">
      <v-card-title>Select Operator</v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedOperator"
          :items="operators"
          item-title="operator_name"
          item-value="id"
          label="Select Operator"
          @update:modelValue="loadOperatorDetails"
        ></v-select>
      </v-card-text>
    </v-card>

    <!-- Conditionally Show Operator Menus -->
    <v-card v-if="selectedOperator && operatorDetails" class="pa-4">
      <v-card-title>{{
        operatorDetails ? operatorDetails.operator_name : ""
      }}</v-card-title>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="operatorDetails.operator_name"
            label="Operator Name"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="updateOperatorName"
              color="primary"
              :disabled="!operatorDetails.operator_name"
              :loading="loadingState.isLoading"
              >Update name</v-btn
            >
        </v-col>
       

        <v-col cols="12">
          <!-- Admin and Generic User Settings -->
          <v-switch
            v-model="operatorSettings.admin"
            label="Admin"
            color="secondary"
            @change="updateOperatorSettings('admin', operatorSettings.admin)"
          ></v-switch>

          <v-switch
            v-model="operatorSettings.generic_user"
            label="Generic User"
            color="secondary"
            @change="
              updateOperatorSettings(
                'generic_user',
                operatorSettings.generic_user
              )
            "
          ></v-switch>
          <v-select
            v-model="operatorSettings.role_id"
            :items="roles"
            item-title="name"
            item-value="id"
            label="Role"
            required
            @update:modelValue="
              updateOperatorSettings('role_id', operatorSettings.role_id)
            "
          ></v-select>
        </v-col>
        <v-col cols="12">
          <!-- Add More Alias -->
          <v-card class="mb-3 pa-2">
            <v-card-title>Add Alias</v-card-title>
            <v-text-field v-model="newAlias" label="New Alias"></v-text-field>
            <v-select
              v-model="newDataSourceId"
              :items="dataSources"
              item-title="source_name"
              item-value="id"
              label="Data Source"
            ></v-select>
            <v-btn
              @click="addAliasForSelectedOperator"
              color="primary"
              :disabled="!newAlias || !newDataSourceId"
              :loading="loadingState.isLoading"
              >Add Alias</v-btn
            >

            <v-divider class="my-3 mx-0 px-0" />
            <v-data-table
              v-if="operatorDetails && operatorDetails.operator_aliases.length"
              :headers="aliasHeaders"
              :items="operatorDetails.operator_aliases"
            >
              <template #[`item.actions`]="{ item }">
                <v-btn
                  icon="mdi-delete"
                  @click="deleteAlias(item.id)"
                /> </template
            ></v-data-table>
            <p v-else class="pa-2">
              this operator does not have any aliases yet
            </p>
          </v-card>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
    </v-card>

    <v-card class="mb-5">
      <v-card-title>Merge Operators</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="mergeFromOperator"
              :items="mergeFromOperators"
              item-title="operator_name"
              item-value="id"
              label="Select Operator to merge from"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="mergeToOperator"
              :items="mergeToOperators"
              item-title="operator_name"
              item-value="id"
              label="Select Operator to merge into"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn :disabled="(!mergeFromOperator || !mergeToOperator)" @click="mergeOperators()">Merge </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Snackbar for Notifications -->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      :color="snackbar.color"
      top
      >{{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>
  
  <script>
import internalApi from "../apis/internal";
import { useLoadingState } from "../store/loadingState";
import { getHeaders } from "../helpers/commonFunctions";
export default {
  name: "OperatorPage",
  data() {
    return {
      form: {
        operator_name: "",
        auth0_id: "",
        superuser: false,
        alias: "",
        alis_id: "",
        data_source_id: null,
        role_id: null, // Add role_id to the form
      },
      search: "",
      operators: [],
      operatorsHeaders: [],
      aliases: [], // To store all existing aliases for validation
      dataSources: [],
      roles: [],

      selectedOperator: null,
      newAlias: "",
      newDataSourceId: null,

      operatorDetails: null,
      operatorSettings: {
        admin: false,
        generic_user: false,
        role_id: null,
      },

      aliasHeaders: [
        { title: "alias", value: "alias", sortable: true },
        { title: "data source", value: "data_source_id", sortable: true },
        { title: "", value: "actions", sortable: true },
      ],
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      loadingState: useLoadingState(),
      mergeFromOperator: null,
      mergeToOperator: null,
    };
  },
  computed: {
    canCreateUser() {
      return (
        this.form.operator_name &&
        this.form.alias &&
        this.form.data_source_id &&
        this.form.role_id
      );
    },
    mergeFromOperators() {
      if(!this.mergeToOperator) return this.operators 
      return this.operators.filter(op => op.id !== this.mergeToOperator)
    },
    mergeToOperators() {
      if(!this.mergeFromOperator) return this.operators 
      return this.operators.filter(op => op.id !== this.mergeFromOperator)
    },
  },
  created() {
    this.getOperators();
    this.getDataSources();
    this.getAllAliases(); // Fetch all aliases for validation

    this.getRoles();
  },
  methods: {
    loadInitialData(){
      this.getOperators();
      this.getDataSources();
      this.getAllAliases(); // Fetch all aliases for validation

      this.getRoles();
    },
    async mergeOperators(){
      const confirm = await this.$confirm("Are you sure you want to merge these 2 operators, this cannot be undone?")
      if(!confirm) return
      try {
        const reqBody = {
          from_operator_id: this.mergeFromOperator,
          into_operator_id: this.mergeToOperator
        }
        await internalApi.genericPost("activities/merge-operators", reqBody);
        
        this.showSnackbar("operators have been merged");
      } catch (error) {
        this.showSnackbar("Error merging operators" + error);
      }
    },
    async deleteAlias(id) {
      try {
        await internalApi.genericDelete("/OperatorAlias", id);
        this.operatorDetails.operator_aliases =
          this.operatorDetails.operator_aliases.filter((row) => row.id !== id);
        this.showSnackbar("alias deleted");
      } catch (error) {
        this.showSnackbar("Error deleting entry" + error);
      }
    },
    // Fetch all operators
    async getOperators() {
      try {
        const response = await internalApi.genericGet("Operators");
        this.operators = response;
        this.operatorsHeaders = getHeaders(
          response,
          ["superuser", "auth0_id"],
          true
        );
      } catch (error) {
        this.showSnackbar("Error loading operators", "error");
      }
    },
    // Delete an operator by ID
    async deleteOperator(id) {
      try {
        await internalApi.genericDelete("Operators", id);
        this.operators = this.operators.filter(
          (operator) => operator.id !== id
        );
        this.showSnackbar(`operator deleted`, "success");
        this.getAllAliases();
      } catch {
        this.showSnackbar("Error deleting operator", "error");
      }
    },

    // Fetch all existing aliases
    async getAllAliases() {
      try {
        const response = await internalApi.genericGet("OperatorAlias");
        this.aliases = response;
      } catch (error) {
        this.showSnackbar("Error loading aliases", "error");
      }
    },

    // Fetch data sources for v-select
    async getDataSources() {
      try {
        const response = await internalApi.genericGet("DataSource");
        this.dataSources = response;
      } catch (error) {
        this.showSnackbar("Error loading data sources", "error");
      }
    },

    async getRoles() {
      try {
        const roles = await internalApi.genericGet("Roles");
        this.roles = roles;
      } catch (error) {
        this.showSnackbar("Error loading roles", "error");
      }
    },

    // Load operator details with aliases, and operator settings
    async loadOperatorDetails() {
      try {
        const response = await internalApi.genericGet(
          `Operators/${this.selectedOperator}`,
          { associations: "operator_aliases,operator_setting" }
        );
        this.operatorDetails = response;

        // Set operator settings (admin and generic_user)
        this.operatorSettings.admin = response.operator_setting?.admin || false;
        this.operatorSettings.generic_user =
          response.operator_setting?.generic_user || false;
        this.operatorSettings.role_id =
          response.operator_setting?.role_id || null;
          
      } catch (error) {
        this.showSnackbar("Error loading operator details", "error");
      }
    },

    // Update operator settings when toggled
    async updateOperatorSettings(setting, value) {
      try {
        const id = this.operatorDetails.operator_setting?.id || null;
        await internalApi.genericPut(`OperatorSettings`, id, {
          [setting]: value,
        });
        this.showSnackbar(`operator settings updated successfully`, "success");
      } catch (error) {
        this.showSnackbar(`Error updating ${setting}`, "error");
      }
    },
    async updateOperatorName(){
      try {
        const id = this.operatorDetails?.id || null;
        await internalApi.genericPut(`Operators`, id, {
          operator_name: this.operatorDetails.operator_name,
        });
        this.showSnackbar(`operator updated successfully`, "success");
      } catch (error) {
        this.showSnackbar(`Error updating operator`, "error");
      }
    },

    // Validate and Create Operator with Alias
    async createOperatorWithAlias() {
      try {
        if (!this.canCreateUser) return;
        // Check if the alias already exists
        if (
          this.form.alias &&
          this.aliases.some((alias) => alias.alias === this.form.alias)
        ) {
          this.showSnackbar("Alias already exists", "error");

          return;
        }

        // Step 1: Create the operator
        const operatorResponse = await internalApi.genericPost("Operators", {
          operator_name: this.form.operator_name,
          superuser: this.form.superuser,
        });
        await internalApi.genericPost("OperatorSettings", {
          operator_id: operatorResponse.id,
          admin: this.form.admin,
          generic_user: this.form.generic_user,
          role_id: this.form.role_id,
        });

        // Step 2: If alias is provided, create the alias entry
        if (this.form.alias) {
          await internalApi.genericPost("OperatorAlias", {
            operator_id: operatorResponse.id,
            alias: this.form.alias,
            alis_id: this.form.alis_id,
            data_source_id: this.form.data_source_id,
          });
        }

        this.getOperators();
        this.getAllAliases();
        this.resetForm();
        this.showSnackbar("Operator created successfully", "success");
      } catch (error) {
        this.showSnackbar("Error creating operator or alias", "error");
      }
    },

    // Add Alias for the Selected Operator
    async addAliasForSelectedOperator() {
      try {
        // Check if the alias already exists
        if (this.aliases.some((alias) => alias.alias === this.newAlias)) {
          this.showSnackbar("Alias already exists", "error");

          return;
        }

        await internalApi.genericPost("OperatorAlias", {
          operator_id: this.selectedOperator,
          alias: this.newAlias,
          data_source_id: this.newDataSourceId,
        });

        this.loadOperatorDetails(); // Refresh aliases after adding
        this.showSnackbar("Alias added successfully", "success");
      } catch (error) {
        this.showSnackbar("Error adding alias", "error");
      }
    },

    // Show a snackbar message
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },

    // Reset the form to its default state
    resetForm() {
      this.form = {
        operator_name: "",
        auth0_id: "",
        superuser: false,
        alias: "",
        alis_id: "",
        data_source_id: null,
        role_id: null, // Add role_id to the form
      };
    },
  },
};
</script>
  
  <style scoped></style>
  