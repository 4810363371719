<template>
    <v-container max-width="70vw">
      <!-- Form to Create Activity Log -->
      <v-card class="mb-5">
        <v-card-title>Create Activity Log</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="createActivityLog">
            <v-text-field v-model="form.date" label="Date" type="date" required></v-text-field>
            <v-select v-model="form.site_id" :items="sites" label="Site" item-title="reference_one" item-value="id"></v-select>
            <v-text-field v-model="form.location" label="Location"></v-text-field>
            <v-select v-model="form.customer" :items="customers" label="Customer" item-title="name" item-value="code"></v-select>
            <v-select v-model="form.operator_id" :items="operators" label="Operator" item-title="operator_name" item-value="id" required></v-select>
            <v-text-field v-model="form.activity_start" label="Activity Start" type="datetime-local" required></v-text-field>
            <v-text-field v-model="form.activity_finish" label="Activity Finish" type="datetime-local" required></v-text-field>
            <v-select v-model="form.activity_type_id" :items="activityTypes" label="Activity Type" item-title="name" item-value="id"></v-select>
            <v-text-field v-model="form.reference_one" label="Reference One"></v-text-field>
            <v-text-field v-model="form.reference_two" label="Reference Two"></v-text-field>
            <v-text-field v-model="form.reference_three" label="Reference Three"></v-text-field>
            <v-btn type="submit" color="primary" :loading="loading" :disabled="!form.operator_id">Create</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <!-- Data Table for Activity Logs -->
      <v-card>
        <v-card-title>Activity Logs</v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="activityLogs">
            <template #[`item.activity_start`]="{ item }">
              {{ formatDate(item.activity_start, true, false) }}
            </template>
            <template #[`item.activity_finish`]="{ item }">
              {{ formatDate(item.activity_finish, true, false) }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn icon="mdi-pencil" outlined @click="openUpdateDialog(item)" />
              <v-btn icon="mdi-delete" @click="deleteActivityLog(item.id)" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
  
      <!-- Update Activity Log Dialog -->
      <v-dialog v-model="updateDialog" max-width="600px">
        <v-card>
          <v-card-title>Update Activity Log</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="updateActivityLog">
              <v-text-field v-model="form.date" label="Date" type="date" required></v-text-field>
              <v-select v-model="form.site_id" :items="sites" label="Site" item-title="reference_one" item-value="id"></v-select>
              <v-text-field v-model="form.location" label="Location"></v-text-field>
              <v-text-field v-model="form.customer" label="Customer"></v-text-field>
              <v-select v-model="form.operator_id" :items="operators" label="Operator" item-title="operator_name" item-value="id" required></v-select>
              <v-text-field v-model="form.activity_start" label="Activity Start" type="datetime-local" required></v-text-field>
              <v-text-field v-model="form.activity_finish" label="Activity Finish" type="datetime-local" required></v-text-field>
              <v-select v-model="form.activity_type_id" :items="activityTypes" label="Activity Type" item-title="name" item-value="id"></v-select>
              <v-text-field v-model="form.reference_one" label="Reference One"></v-text-field>
              <v-text-field v-model="form.reference_two" label="Reference Two"></v-text-field>
              <v-text-field v-model="form.reference_three" label="Reference Three"></v-text-field>
              <v-btn type="submit" color="primary" :loading="loading" :disabled="!form.operator_id">Update</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

        <!-- Snackbar for notifications -->
        <v-snackbar v-model="snackbar.show" :timeout="5000" :color="snackbar.color" top right>
        {{ snackbar.message }}
      </v-snackbar>

    </v-container>
  </template>
  
  <script>
  import internalApi from '../apis/internal';
  import { getHeaders, formatDate } from '../helpers/commonFunctions';
  import { authStore } from '../store/authStore';

  export default {
    name: 'ActivityLogPage',
    data() {
      return {
        form: {
          date: '',
          site_id: '',
          location: '',
          customer: '',
          operator_id: null,
          activity_start: '',
          activity_finish: '',
          activity_type_id: null,
          reference_one: '',
          reference_two: '',
          reference_three: '',
        },
        activityLogs: [], // Holds the list of activity logs
        operators: [], // Holds the list of operators
        activityTypes: [], // Holds the list of activity types
        customers: [],
        sites: [], // Holds the list of sites
        aliases: [],
        loading: false, // Loading state for the form
        headers: [], // Data table headers
        endpoint: 'ActivityLog', // API endpoint for activity logs
        updateDialog: false, // Control the visibility of the update dialog
        currentActivityLogId: null, // ID of the current activity log to update
        snackbar: {
          show: false, // Controls visibility of snackbar
          message: '', // Message to be displayed in snackbar
        },
      };
    },
    created() {
      this.getActivityLogs();
      this.getOperators(); // Fetch operators for the select options
      this.getActivityTypes(); // Fetch activity types for the select options
      this.getSites();
      this.getCustomers();
      this.getAliases();
    },
    methods: {
      formatDate, 
      async getAliases() {
        try {
          const response = await internalApi.genericGet('OperatorAlias');
          this.aliases = response;
        } catch (error) {
          this.showSnackbar('Error loading aliases', 'error');
        }
      },
        showSnackbar(message, color) {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
        },
      async getActivityLogs() {
        try {
          const response = await internalApi.genericGet(this.endpoint);
          this.activityLogs = response;
          this.headers = getHeaders(response, [], true);
        } catch {
          this.showSnackbar('Error loading activity logs', 'error');
        }
      },
      async getOperators() {
        try {
          const response = await internalApi.genericGet('Operators');
          this.operators = response;
        } catch {
          this.showSnackbar('Error loading operators', 'error');
        }
      },
      async getCustomers() {
        try {
          const response = await internalApi.genericGet('/activities/customers');
          this.customers = response;
        } catch {
          this.showSnackbar('Error loading companies', 'error');
        }
      },
      async getSites() {
        try {
          const response = await internalApi.genericGet('Sites');
          this.sites = response;
        } catch {
          this.showSnackbar('Error loading sites');
        }
      },
      async getActivityTypes() {
        try {
          const response = await internalApi.genericGet('ActivityTypes');
          this.activityTypes = response;
        } catch (error) {
          this.showSnackbar('Error loading activity types' + error, 'error');
        }
      },
      async createActivityLog() {
        try {
          this.loading = true;
          let form = this.form 
          form.auth0_id = authStore.auth0Operator.auth0_id
          let alias = this.aliases.find( alias => alias.operator_id === form.operator_id)
          if(alias){
            form.alias = alias.alias 
          }
          await internalApi.genericPost(this.endpoint, this.form);
          this.getActivityLogs();
          this.resetForm(); // Reset the form after submission
          this.showSnackbar('Activity log created successfully', 'success');
        } catch (error) {
          this.showSnackbar('Error creating activity log'+ error, 'error');
        } finally {
          this.loading = false;
        }
      },
      openUpdateDialog(item) {
        this.currentActivityLogId = item.id; // Store the ID of the activity log to be updated
        this.form = { ...item }; // Populate the form with the current item data
        this.updateDialog = true; // Show the dialog
      },
      async updateActivityLog() {
        try {
          this.loading = true;
          await internalApi.genericPut(this.endpoint, this.currentActivityLogId, this.form);
          const index = this.activityLogs.findIndex(log => log.id === this.currentActivityLogId);
          if (index !== -1) {
            this.activityLogs[index] = { ...this.form, id: this.currentActivityLogId };
          }
          this.resetForm();
          this.updateDialog = false;
          this.showSnackbar('Activity log updated successfully', 'success');
        } catch (error) {
          this.showSnackbar('Error updating activity log. ' + error, 'error');
        } finally {
          this.loading = false;
        }
      },
      async deleteActivityLog(id) {
        try {
          await internalApi.genericDelete(this.endpoint, id);
          this.activityLogs = this.activityLogs.filter(log => log.id !== id);
          this.showSnackbar('Activity log deleted successfully', 'success');
        } catch (error) {
          this.showSnackbar('Error deleting activity log' + error, 'error');
        }
      },
      resetForm() {
        this.form = {
          date: '',
          site_id: '',
          location: '',
          customer: '',
          operator_id: null,
          activity_start: '',
          activity_finish: '',
          activity_type_id: null,
          reference_one: '',
          reference_two: '',
          reference_three: '',
        };
        this.currentActivityLogId = null;
      },
    },
  };
  </script>
  
  <style scoped></style>
  